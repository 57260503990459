<template>
  <div class="website-css mobile-css">
    <MenuHead @func="getMsgFormSon"></MenuHead>
    <!-- banner beg -->
    <div class="banner-main">
      <swiper
          :options="swiperOption"
          ref="mySwiper"
          v-if="this.languageList[0].bannerList.length != 0">
        <swiper-slide>
          <div class="Solution-banner AboutUs-banner AboutUs-banner-block" style="height: 100%;line-height: 0;">
            <div class="banner-text animated fadeInLeft"  style="padding-top: 0.25rem;">
              {{ this.bannerList[0].List[0].bannerList[0].title }}
            </div>
            <div class="banner-sub-text animated fadeInRight" style="padding-top: 0.25rem;">
              {{ this.bannerList[0].List[0].bannerList[0].subTitle }}
            </div>
            <img
                src="https://eletell.oss-cn-beijing.aliyuncs.com/H5_Activity/Website/banner2new.jpg"
                alt
                class="swiper-img"
                style="height: 100%;border: 0 solid;margin-left: 0;left: 0;line-height: 0;font-size: 0"
            /></div>
        </swiper-slide>
      </swiper>
    </div>
    <!-- banner end -->

    <!-- main beg -->
    <div class="w1200 Mobile-pad25">
      <!-- 圆环 end -->
      <div class="title-font36">
        {{ this.languageList[1].titleText }}
        <span></span>
      </div>
      <div class="title-font18">
        {{ this.languageList[1].textContent }}
      </div>

      <!-- 轮播 icon beg-->
      <div class="w1200 pc-div-display">
        <div class="icon-swpper">
          <router-link
              target="_blank"
              class="more-btn animated rubberBand"
              to="/Product-WLZG"
              v-if="cur == 0"
          >{{ this.languageList[1].List[0].more }}
          </router-link
          >
          <router-link
              class="more-btn animated rubberBand"
              to="/Product-BSYD"
              v-if="cur == 1"
              target="_blank"
          >{{ this.languageList[1].List[1].more }}
          </router-link
          >
          <router-link
              class="more-btn animated rubberBand"
              to="/Product-XXNL"
              v-if="cur == 2"
              target="_blank"
          >{{ this.languageList[1].List[2].more }}
          </router-link
          >
          <router-link
              class="more-btn animated rubberBand"
              to="/Product-XQTX"
              v-if="cur == 3"
              target="_blank"
          >{{ this.languageList[1].List[3].more }}
          </router-link
          >
          <div class="Home-phone-img">
            <img
                class="phone-main-img"
                src="../assets/image/Website-img/phone-border.png"
            />
            <img
                class="phone-center-img animated fadeInRight"
                v-if="cur == 0"
                src="../assets/image/Website-img/wlzg-phone-img.png"
            />
            <img
                class="phone-center-img animated fadeInRight"
                v-if="cur == 1"
                src="../assets/image/Website-img/bsud-phone-img.png"
            />
            <img
                class="phone-center-img animated fadeInRight"
                v-if="cur == 2"
                src="../assets/image/Website-img/xxnl-phone-img.png"
            />
            <img
                class="phone-center-img animated fadeInRight"
                v-if="cur == 3"
                src="../assets/image/Website-img/xqtx-phone-img.png"
            />
          </div>

          <div class="Home-icon-tab">
            <ul>
              <li @click="cur = 0" :class="{ active: cur == 0 }">
                <img src="../assets/image/Website-img/wlzg-logo-icon.png"/>
              </li>
              <li @click="cur = 1" :class="{ active: cur == 1 }">
                <img
                    src="https://eletell.oss-cn-beijing.aliyuncs.com/H5_Activity/bsyd-logo-icon.png"
                />
              </li>
              <li @click="cur = 2" :class="{ active: cur == 2 }">
                <img src="../assets/image/Website-img/xxnl-logo-icon.png"/>
              </li>
              <li @click="cur = 3" :class="{ active: cur == 3 }">
                <img src="../assets/image/Website-img/xqtx-logo-icon.png"/>
              </li>
            </ul>
            <div class="icon-text" v-if="cur == 0">
              <p>{{ this.languageList[1].List[0].title }}</p>
              <p>
                {{ this.languageList[1].List[0].content }}
              </p>
            </div>
            <div class="icon-text" v-if="cur == 1">
              <p>{{ this.languageList[1].List[1].title }}</p>
              <p>
                {{ this.languageList[1].List[1].content }}
              </p>
            </div>
            <div class="icon-text" v-if="cur == 2">
              <p>{{ this.languageList[1].List[2].title }}</p>
              <p>
                {{ this.languageList[1].List[2].content }}
              </p>
            </div>
            <div class="icon-text" v-if="cur == 3">
              <p>{{ this.languageList[1].List[3].title }}</p>
              <p>
                {{ this.languageList[1].List[3].content }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- 轮播 icon end -->
      <!-- 轮播 icon beg-->
      <div class="w1200 phone-div-display">
        <div class="icon-swpper">
          <div class="Home-phone-img">
            <swiper
                :options="swiperOption"
                class="phone-swpper"
                ref="mySwiper"
                v-if="bannerList.length != 0"
            >
              <swiper-slide>
                <div class="slide-img">
                  <img src="../assets/image/Website-img/wlzg-phone-img.png"/>
                </div>
                <p>
                  <a
                      href="https://wlzgof.eletell.com/mobile.html"
                      target="_blank"
                  >{{ this.languageList[1].List[0].title }}</a
                  >
                </p>
              </swiper-slide>
              <swiper-slide>
                <div class="slide-img">
                  <img src="../assets/image/Website-img/bsud-phone-img.png"/>
                </div>
                <p>
                  <a href="https://bsyd.eletell.com" target="_blank">{{
                      this.languageList[1].List[1].title
                    }}</a>
                </p>
              </swiper-slide>
              <swiper-slide>
                <div class="slide-img">
                  <img src="../assets/image/Website-img/xxnl-phone-img.png"/>
                </div>
                <p>
                  <a href="https://kid.eletell.com" target="_blank">{{
                      this.languageList[1].List[2].title
                    }}</a>
                </p>
              </swiper-slide>
              <swiper-slide>
                <div class="slide-img">
                  <img src="../assets/image/Website-img/xqtx-phone-img.png"/>
                </div>
                <p>
                  <a href="https://kid.eletell.com" target="_blank">{{
                      this.languageList[1].List[3].title
                    }}</a>
                </p>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
              <!-- <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div> -->
            </swiper>
          </div>
        </div>
      </div>
      <!-- 轮播 icon end -->
    </div>
    <!-- main end -->
    <!-- 高校logo beg -->
    <div class="school-icon">
      <div class="icon-bg">
        <img src="../assets/image/Website-img/mapbg.png"/>
      </div>
      <div class="title-font36">
        {{ this.languageList[2].titleText }}
        <span></span>
      </div>
      <!-- <vue-seamless-scroll
        :data="newsList"
        :class-option="optionLeft"
        class="seamless-warp2"
      >
        <ul class="item">
          <li v-for="(item,index) in newsList" v-text="item.title" :key="index"></li>
        </ul> -->

      <!-- <vue-seamless-scroll
        :data="newsList"
        :class-option="optionLeft"
        class="school-warp2"
      > -->
      <ul class="school-ul">
        <li v-for="(item, index) in schoolList" :key="index">
          <img :src="item.img"/>
        </li>
      </ul>
      <!-- </vue-seamless-scroll> -->
    </div>
    <!-- 高校logo end -->

    <!-- 荣誉&资质 beg -->
    <div class="Enterprise-bg-bai">
      <div class="title-font36">
        {{ this.languageList[3].titleText }}
        <span></span>
      </div>

      <div class="hundred Mobile-pad25">
        <ul class="Enterprise-Medal Enterprise-Medal-ZZ">
          <li
              v-for="(item, index) in this.languageList[3].enterprise"
              :key="index"
          >
            <div class="Enterprise-img">
              <img :src="item.img"/>
            </div>
            <div class="Enterprise-text">{{ item.title }}</div>
          </li>
          <!-- <li>
            <div class="Enterprise-img">
              <img :src="../assets/image/Website-img/rongyao2.png" />
            </div>
            <div class="Enterprise-text">中国教育游戏专委会</div>
          </li>
          <li>
            <div class="Enterprise-img">
              <img :src="../assets/image/Website-img/rongyao3.png" />
            </div>
            <div class="Enterprise-text">信息安全等级保护</div>
          </li>
          <li>
            <div class="Enterprise-img">
              <img :src="../assets/image/Website-img/rongyao4.png" />
            </div>
            <div class="Enterprise-text">多项自主知识产权</div>
          </li> -->
        </ul>
      </div>
    </div>
    <!-- 荣誉&资质 end -->

    <!-- 媒体关注与报道 beg -->
    <div class="Enterprise-bg-f8">
      <div class="title-font36">
        {{ this.languageList[4].titleText }}
        <span></span>
      </div>

      <div class="w1200 Mobile-pad25">
        <ul class="Enterprise-Medal Media-div">
          <li
              v-for="(item, index) in this.languageList[4].enterprise"
              :key="index"
          >
            <a :href="item.href" target="_blank">
              <div class="Enterprise-img">
                <img :src="item.img"/>
              </div>
              <div class="Enterprise-text">
                <p>{{ item.title }}</p>
                <p>
                  {{ item.content }}
                </p>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!-- 媒体关注与报道 end -->

<!--    &lt;!&ndash; 原创冥想和音乐 beg &ndash;&gt;-->
<!--    <div class="Enterprise-bg-bai">-->
<!--      <div class="title-font36" style="padding-bottom: 0.25rem;">-->
<!--        {{ this.languageList[5].titleText }}-->
<!--        <span></span>-->
<!--        <div v-if="loginUser" style="font-size: 0.15rem;margin-top: 0.2rem;">-->
<!--          当前登录用户：{{ loginUser.nickName }}-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="hundred Mobile-pad25" style="margin-top: 0.6rem">-->
<!--        <ul class="Enterprise-SMedal Enterprise-Medal-ZZ">-->
<!--          <li v-for="(item, index) in this.languageList[5].enterprise"-->
<!--              :key="index" style="box-shadow: none">-->
<!--            <div style="text-align: center;position: relative">-->
<!--              <img :class="[playIndex===index?'music-icon-go':'music-icon']"-->
<!--                   :src="item.img"/>-->
<!--              <i v-if="playIndex!==index"-->
<!--                 class="el-icon-video-play play-icon"-->
<!--                 @click="playMusic(index)"></i>-->
<!--              <i v-if="playIndex===index"-->
<!--                 class="el-icon-video-pause play-icon" @click="pauseMusic(index)"></i>-->
<!--            </div>-->
<!--            <div>-->
<!--              <audio id="audio">-->
<!--                您的浏览器不支持 audio 标签。-->
<!--              </audio>-->
<!--            </div>-->
<!--            <div class="Enterprise-text"-->
<!--                 style="font-size: 0.2rem;padding: 10px 0;text-align: center">-->
<!--              {{ item.title }}-->
<!--            </div>-->
<!--            <div class="Enterprise-text"-->
<!--                 style="font-size: 0.17rem;height:0.2rem;padding: 10px 10px 20px 10px"-->
<!--                 @click="onClickPay(index,true)">-->
<!--              <span>付费</span>-->
<!--              <span-->
<!--                  style="font-size: 0.2rem;color:#FD0A09;font-weight: bolder;margin-left: 5px">￥48</span>-->
<!--            </div>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->
<!--    </div>-->
<!--    &lt;!&ndash; 原创冥想和音乐 end &ndash;&gt;-->

<!--    &lt;!&ndash; 原创动漫形象和小象 beg &ndash;&gt;-->
<!--    <div class="Enterprise-bg-f8">-->
<!--      <div class="title-font36" style="padding-bottom: 0.25rem;">-->
<!--        {{ this.languageList[6].titleText }}-->
<!--        <span></span>-->
<!--        <div v-if="loginUser" style="font-size: 0.15rem;margin-top: 0.2rem;">-->
<!--          当前登录用户：{{ loginUser.nickName }}-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="w1200 Mobile-pad25">-->
<!--        <ul class="Enterprise-SMedal Enterprise-Medal-ZZ" style="background: transparent">-->
<!--          <li v-for="(item, index) in this.languageList[6].enterprise"-->
<!--              :key="index" style="box-shadow: none;background-color: transparent">-->
<!--            <div style="text-align: center;position: relative">-->
<!--              <img class="music-icon"-->
<!--                   :src="item.img"/>-->
<!--            </div>-->
<!--            <div class="Enterprise-text"-->
<!--                 style="font-size: 0.2rem;padding: 10px 0;text-align: center">-->
<!--              {{ item.title }}-->
<!--            </div>-->
<!--            <div class="Enterprise-text"-->
<!--                 style="font-size: 0.17rem;height:0.2rem;padding: 10px 10px 20px 10px"-->
<!--                 @click="onClickPay(index,false)">-->
<!--              <span>付费</span>-->
<!--              <span-->
<!--                  style="font-size: 0.2rem;color:#FD0A09;font-weight: bolder;margin-left: 5px">￥68</span>-->
<!--            </div>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->
<!--    </div>-->
<!--    &lt;!&ndash; 原创动漫形象和小象 end &ndash;&gt;-->
    <web-bottom ref="child"></web-bottom>
    <!--登录弹窗-->
    <el-dialog
        title="请登录"
        :visible.sync="dialogVisible"
        width="30%">
      <el-form :model="ruleForm" status-icon ref="ruleForm" label-width="100px"
               label-position="top"
               class="demo-ruleForm">
        <el-form-item label="账号" prop="account">
          <el-input v-model="ruleForm.account" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="pass">
          <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="login()">登录</el-button>
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button style="margin-left: 0.2rem;color: grey"
                     @click="dialogVisible = false;registerDialogVisible = true" type="text">邮箱注册
          </el-button>
        </el-form-item>
      </el-form>

      <div style="height: 20px"></div>
      <div style="background-color: #DDDDDD;height: 1px;margin-bottom: 20px"></div>

      <el-form :model="wlzgForm" status-icon ref="ruleForm"
               label-position="top"
               class="demo-ruleForm">
        <el-form-item label="未来之光会员可免费下载，输入ID:" prop="account">
          <el-input v-model="wlzgForm.account" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="verifyDownload()">验证下载</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--注册弹窗-->
    <el-dialog
        title="注册"
        :visible.sync="registerDialogVisible"
        width="30%">
      <el-form :model="registerForm" status-icon ref="ruleForm" label-width="100px"
               class="demo-ruleForm">
        <el-form-item label="账号（邮箱）" prop="account">
          <el-input type="mail" v-model="registerForm.account" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="pswd">
          <el-input type="password" v-model="registerForm.pswd" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="再次输入" prop="checkPswd">
          <el-input type="password" v-model="registerForm.checkPswd" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="register">确定</el-button>
          <el-button @click="registerDialogVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--付费弹窗-->
    <el-dialog
        title="付费充值"
        :visible.sync="showPayDialog"
        width="30%">
      <el-image :src="require('../assets/image/dxzk_wx_skm.png')">
      </el-image>
      <span>如果支付遇到问题，请联系微信客服：wlzg_002</span>
      <div class="dialog-subtitle">
        <span></span>
      </div>
      <el-form :model="wlzgForm" status-icon ref="ruleForm"
               label-position="top"
               class="demo-ruleForm">
        <el-form-item label="未来之光会员可免费下载，输入ID:" prop="account">
          <el-input v-model="wlzgForm.account" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="verifyDownload()">验证下载</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
// 引入插件
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import MenuHead from '../components/MenuHead'
import WebBottom from '../components/WebBottom'
import Function from '../assets/js/function'
import ChineseJson from '../assets/json/Chinese-Json.json'
import EnglishJson from '../assets/json/English-Json.json'
import 'swiper/css/swiper.css'

import music1 from '../assets/audio/music1.mp3'
import music2 from '../assets/audio/music2.mp3'
import music3 from '../assets/audio/music3.mp3'
import music4 from '../assets/audio/music4.mp3'
import music5 from '../assets/audio/music5.mp3'
import music6 from '../assets/audio/music6.mp3'
import music7 from '../assets/audio/music7.mp3'

import ApiService from "../assets/js/ApiService"
import Constant from "../assets/js/Constant"
import md5 from 'js-md5'

export default {
  name: 'index',
  components: {
    MenuHead,
    WebBottom,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      dialogVisible: false,
      registerDialogVisible: false,
      showPayDialog: false,
      loginUser: null,
      ruleForm: {
        account: null,
        pass: null,
      },
      registerForm: {
        account: null,
        pswd: null,
        checkPswd: null,
      },
      wlzgForm: {
        account: null
      },
      cur: 0, //默认选中第一个tab
      ChineseJson: JSON.parse(JSON.stringify(ChineseJson)), //中文配表
      EnglishJson: JSON.parse(JSON.stringify(EnglishJson)), //英文配表
      languageJson: [],
      musics: [
        music1,
        music2,
        music3,
        music4,
        music5,
        music6,
        music7,
      ],
      musicsUrls: [
        "https://bucket-sh-dxzk.oss-cn-shanghai.aliyuncs.com/music1.mp3?Expires=1641892864&OSSAccessKeyId=TMP.3Kgo9r5rPcqnKUkoyEzuM7f9UPEQoX1UjmBEdGRLGWxjiFZyXVyPXsW1u6vDkWVUG9MjMTe8hLi5uyERR4ssd4y3jKW499&Signature=dgcOA49GA94YIlbdU8HuVDmvao4%3D&versionId=CAEQNBiBgMC.1fCg8hciIDkxYTBhZDNmNjNlYzRjNTFhZjExZmYxYzE4OGNkZDhk&response-content-type=application%2Foctet-stream",
        "https://bucket-sh-dxzk.oss-cn-shanghai.aliyuncs.com/music2.mp3?Expires=1641892954&OSSAccessKeyId=TMP.3Kgo9r5rPcqnKUkoyEzuM7f9UPEQoX1UjmBEdGRLGWxjiFZyXVyPXsW1u6vDkWVUG9MjMTe8hLi5uyERR4ssd4y3jKW499&Signature=Md%2BqUbUCcvEdvGJGAIPAut2w5RQ%3D&versionId=CAEQNBiBgIDL1vCg8hciIDU2NGYyYTA5NmU2OTQyMzY5NWQ0OGUzNWYzYjFhYzYw&response-content-type=application%2Foctet-stream",
        "https://bucket-sh-dxzk.oss-cn-shanghai.aliyuncs.com/music3.mp3?Expires=1641892942&OSSAccessKeyId=TMP.3Kgo9r5rPcqnKUkoyEzuM7f9UPEQoX1UjmBEdGRLGWxjiFZyXVyPXsW1u6vDkWVUG9MjMTe8hLi5uyERR4ssd4y3jKW499&Signature=TGtwH%2Fa%2FCTRFlITAJH9eWw%2FrWnE%3D&versionId=CAEQNBiBgMCA0PCg8hciIGM4NGU2ZjI3NTg1YzQ1YTdiOGIxZjdjNWU4YTFkZmZk&response-content-type=application%2Foctet-stream",
        "https://bucket-sh-dxzk.oss-cn-shanghai.aliyuncs.com/music4.mp3?Expires=1641892968&OSSAccessKeyId=TMP.3Kgo9r5rPcqnKUkoyEzuM7f9UPEQoX1UjmBEdGRLGWxjiFZyXVyPXsW1u6vDkWVUG9MjMTe8hLi5uyERR4ssd4y3jKW499&Signature=qNjXmf2wSTgbNtVibLsao7dM0yY%3D&versionId=CAEQNBiBgMDIyfCg8hciIDc1NGRhNjg4ZWJkMzQ0Y2M4MDViNjQzMjVhM2Y1ZDA4&response-content-type=application%2Foctet-stream",
        "https://bucket-sh-dxzk.oss-cn-shanghai.aliyuncs.com/music5.mp3?Expires=1641893002&OSSAccessKeyId=TMP.3Kgo9r5rPcqnKUkoyEzuM7f9UPEQoX1UjmBEdGRLGWxjiFZyXVyPXsW1u6vDkWVUG9MjMTe8hLi5uyERR4ssd4y3jKW499&Signature=hSU9wil7hhYO%2BeJRzMZR8IyxeKk%3D&versionId=CAEQNBiBgMDv0_Cg8hciIGUxY2RiNWM4NDU1ZjRlZWRhZmM4ZDkwOGZiYjMwZWE4&response-content-type=application%2Foctet-stream",
        "https://bucket-sh-dxzk.oss-cn-shanghai.aliyuncs.com/music6.mp3?Expires=1641893018&OSSAccessKeyId=TMP.3Kgo9r5rPcqnKUkoyEzuM7f9UPEQoX1UjmBEdGRLGWxjiFZyXVyPXsW1u6vDkWVUG9MjMTe8hLi5uyERR4ssd4y3jKW499&Signature=yT89w5s%2Fma%2FNCgnI34D%2BPeQ%2FupM%3D&versionId=CAEQNBiBgMDl0fCg8hciIDE5ZTU5OWI3YmU4OTQ2NGJiZTYzOWFhNmQ5MjZlMWQy&response-content-type=application%2Foctet-stream",
        "https://bucket-sh-dxzk.oss-cn-shanghai.aliyuncs.com/music7.mp3?Expires=1641893031&OSSAccessKeyId=TMP.3Kgo9r5rPcqnKUkoyEzuM7f9UPEQoX1UjmBEdGRLGWxjiFZyXVyPXsW1u6vDkWVUG9MjMTe8hLi5uyERR4ssd4y3jKW499&Signature=blZLqTf9qnbiRZz5meGS68bznR0%3D&versionId=CAEQNBiBgICXyfCg8hciIGU1ODk2MGQyM2YyNzRiMGE4Mzg5ZWJjMWY2ZTQ4OTVh&response-content-type=application%2Foctet-stream"
      ],
      langtype: 1,
      languageList: [],
      BtnType: null,
      bannerlist: [],
      playIndex: -1,
      newsList: [
        {
          title: 'A simple, seamless scrolling for Vue.js',
        },
        {
          title: 'A curated list of awesome things related to Vue.js',
        },
      ],
      bannerList: [
        {img: require('../assets/image/Website-img/banner1.jpg')},
        {img: require('../assets/image/Website-img/banner1.jpg')},
        {img: require('../assets/image/Website-img/banner1.jpg')},
        // { img: require("../assets/image/Website-img/banner1.jpg") },
        // { img: require("../assets/image/Website-img/banner1.jpg") },
      ],
      schoolList: [
        {img: require('../assets/image/Website-img/school.png')},
        {img: require('../assets/image/Website-img/school2.png')},
        // { img: require('../assets/image/Website-img/school3.png') },
        {img: require('../assets/image/Website-img/school4.png')},
        {img: require('../assets/image/Website-img/school5.png')},
        {img: require('../assets/image/Website-img/school6.png')},
        {img: require('../assets/image/Website-img/school7.png')},
        {img: require('../assets/image/Website-img/school8.png')},
        {img: require('../assets/image/Website-img/school9.png')},
      ],
      swiperOption: {
        loop: false,
        autoplay: {
          delay: 3500,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 显示分页
        pagination: {
          el: '.swiper-pagination',
          clickable: true, //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
  props: {
    msg: String,
  },
  mounted() {
    new Swiper('.swiper-container', {
      loop: true,
      // 如果需要分页器
      pagination: '.swiper-pagination',
      // 如果需要前进后退按钮
      nextButton: '.swiper-button-next',
      prevButton: '.swiper-button-prev',
      // 如果需要滚动条
      scrollbar: '.swiper-scrollbar',
    });
    this.loginUser = JSON.parse(localStorage.getItem("loginUser"));
    this.audio = document.getElementById('audio');
    let self = this;
    this.audio.addEventListener("pause", function () {
      // 暂停时会触发，当播放完一首歌曲时也会触发
      self.playIndex = -1;
    });
    this.audio.addEventListener("ended", function () {
      //当播放完一首歌曲时也会触发
      self.playIndex = -1;
    });
  },
  computed: {
    optionLeft() {
      return {
        direction: 2,
        limitMoveNum: 2,
      }
    },
  },
  created() {
    // this.langtype = localStorage.getItem("LanguageTypes");
    // this.IFType();
    if (localStorage.getItem('LanguageTypes') == undefined) {
      this.langtype = 1
      this.IFType()
    } else {
      this.langtype = localStorage.getItem('LanguageTypes')
      this.IFType()
    }
  },

  methods: {
    IFType() {
      if (this.langtype == 1) {
        this.languageJson = this.ChineseJson
        this.bannerList = this.ChineseJson
        this.getList()
      } else {
        this.languageJson = this.EnglishJson
        this.bannerList = this.EnglishJson
        this.getList()
      }
    },
    getMsgFormSon(data) {
      // this.msgFormSon = data;
      this.langtype = data
      this.IFType()
      this.$refs.child.sing(this.langtype)
    },
    getList() {
      // this.ChineseJson.fo
      this.languageJson.forEach((item) => {
        if (item.title == 'Home') {
          this.languageList = item.List
        }
      })
    },
    // APP下载按钮
    downloadAPP(BtnType) {
      switch (BtnType) {
        case 1: {
          Function.downloadAPP()
          break
        }
        case 2: {
          Function.downloadBsydApp()
          break
        }
        case 3: {
          Function.downloadXxnlApp()
          break
        }
      }
    },
    handleSelect(key, keyPath) {
    },
    onSwiper(swiper) {
    },
    onSlideChange() {
    },
    playMusic(index) {
      this.playIndex = index;
      this.audio.src = this.musics[index];
      this.audio.play();
    },
    pauseMusic(index) {
      this.playIndex = -1;
      this.audio.pause();
    },
    showDialog() {
      this.playIndex = -1;
      this.audio.pause();
      this.dialogVisible = true;
    },
    register() {
      //注册
      //检测账号是否是邮箱
      let account = this.registerForm.account;
      let checkPswd = this.registerForm.checkPswd;
      let pswd = this.registerForm.pswd;
      if (!this.isEmail(account)) {
        alert("请检查账号格式");
        return;
      }
      if (pswd && (pswd.length < 8 || pswd.length > 16)) {
        alert("密码长度8~16位");
        return;
      }
      if (!pswd || !checkPswd || pswd !== checkPswd) {
        alert("请检查密码");
        return;
      }
      //注册接口
      let param = "account=" + account + "&nickName=random" + "&pswd=" + md5(pswd);
      let self = this;
      ApiService.post(Constant.REGISTER_API, param).then((result) => {
        let status = result.status;
        if (200 === status) {
          let message = result.data.message;
          if ("1004" === message) {
            alert("该注册用户已存在");
            return;
          }
          if ("1009" === message) {
            alert("请求过于频繁，请稍后再试");
            return;
          }
          if ("成功" === message) {
            //注册成功，记录状态
            //自动登录
            alert("注册成功!");
            self.registerDialogVisible = false;
            self.login(self.registerForm.account, self.registerForm.pswd);
          } else {
            alert(message);
          }

        } else {
          alert("注册失败");
        }
      });
    },
    login(account, pswd) {
      if (!account) {
        account = this.ruleForm.account;
        pswd = this.ruleForm.pass;
      }
      if (!this.isEmail(account)) {
        alert("请检查账号格式");
        return;
      }
      if (pswd && (pswd.length < 8 || pswd.length > 16)) {
        alert("密码长度8~16位");
        return;
      }
      if (!pswd) {
        alert("请检查密码");
        return;
      }
      //登录接口
      let param = "account=" + account + "&pswd=" + md5(pswd);
      let self = this;
      ApiService.post(Constant.LOGIN_API, param).then((result) => {
        let status = result.status;
        if (200 === status) {
          let message = result.data.message;
          if ("1001" === message) {
            alert("用户不存在");
            return;
          }
          if ("成功" === message && result.data.result) {
            //登录成功，记录状态
            alert("登录成功!");
            localStorage.setItem("loginUser", JSON.stringify(result.data.result));
            self.loginUser = result.data.result;
            self.dialogVisible = false;
          } else {
            alert(message);
          }

        } else {
          alert("登录失败");
        }
      });
    },
    isEmail(str) {
      let re = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
      return re.test(str);
    },
    onClickPay(item, downloadVideo) {
      this.downloadVideo = downloadVideo;
      this.downloadVideoIndex = item;
      if (!this.loginUser) {
        this.showDialog()
      } else {
        this.showPayDialog = true;
      }
    },
    verifyDownload() {
      let displayId = this.wlzgForm.account;
      //登录接口
      let param = "di=" + displayId;
      let self = this;
      ApiService.post(Constant.WLZG_VERIFY_VIP, param).then((result) => {
        let status = result.status;
        if (200 === status) {
          let message = result.data.message;
          if ("成功" === message && result.data.result) {
            alert("验证成功！");
            self.wlzgForm.account = null;
            if (self.downloadVideo) {
              try {
                window.location.href = self.musicsUrls[self.downloadVideoIndex];
                self.dialogVisible = false;
                self.registerDialogVisible = false;
                self.showPayDialog = false;
              } catch (e) {
                alert("下载异常！");
              }
            } else {
              alert("动漫资源请联系客服获取！（WX:wlzg_002）");
              self.dialogVisible = false;
              self.registerDialogVisible = false;
              self.showPayDialog = false;
            }
          } else {
            alert("验证失败");
          }

        } else {
          alert("验证失败");
        }
      });

    }
  },
}
</script>
<style scoped>
.AboutUs-banner {
  width: 100%;
  height: 715px;
  overflow: hidden;
  position: relative;
  background: linear-gradient(to right, #2acaff, #0e60ff);
}

.AboutUs-banner-block {
  text-align: center;
  background: url('../assets/image/Website-img/banner-bg.jpg');
}

.AboutUs-banner-block img {
  height: 100%;
  width: 100%;
}

.AboutUs-banner-BSYD {
  width: 100%;
  height: 715px;
  position: relative;
  background: #3cb560;
}

.AboutUs-banner-BSYD .banner-title p {
  color: #ffffff;
}

.Solution-banner {
}

.Solution-banner .w1200 {
  height: 100%;
}

.Solution-banner .wlzg-banner-logo {
  margin-top: 150px;
}

.brain-ul li {
  float: left;
  width: 400px;
  height: 300px;
}

.school-warp2 {
}

.school-ul {
  overflow: hidden;
  width: 100%;
  display: flex;
}

.school-ul li {
  display: inline-block;
  margin-left: 59px;
  text-align: center;

  flex: 1;
}

.school-ul li:nth-of-type(1) {
  margin-left: 0px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.swiper-container {
  width: 100%;
  transform: translate3d(0, 0, 0);
}

.swiper-slide {
  transform: translate3d(0, 0, 0);
}

.swiper-img {
  transform: translate3d(0, 0, 0);
  width: 100%;
}

.play-icon {
  font-size: 0.4rem;
  position: absolute;
  width: 1.5rem;
  margin: auto;
  top: 0.55rem;
  left: 0;
  right: 0;
  opacity: 0.5;
}

.music-icon {
  width: 1.5rem;
}

.dialog-subtitle {
  font-size: 18px;
  color: #303133;
  padding-bottom: 20px;
  padding-top: 20px;
  font-family: '微软雅黑';
}

.music-icon-go {
  width: 1.5rem;
  animation: rotating 6s linear infinite
}
</style>
