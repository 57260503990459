// const HOST = "http://127.0.0.1:39111/gate";
const HOST = "https://swlxapi.eletell.com/gate";
const WLZG_HOST = "https://wlzgadminapi.eletell.com/api";

const REGISTER_API = HOST + "/ow/register";
const LOGIN_API = HOST + "/ow/login";
const WLZG_VERIFY_VIP = WLZG_HOST + "/sadk/iv";


// 公共参数
export  default {
    REGISTER_API,
    LOGIN_API,
    WLZG_VERIFY_VIP
}