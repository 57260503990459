import axios from 'axios';

function checkStatus(response) {
    // 如果http状态码正常，则直接返回数据
    if (
        response &&
        (response.status === 200 ||
            response.status === 304 ||
            response.status === 400)
    ) {
        return response
    }
    // 异常状态下，把错误信息返回去
    return {
        status: -404,
        msg: '系统错误',
    }
}

function checkCode(res) {
    // console.log("checkCode------>", res);
    //判断以下
    // 如果code异常(这里已经包括网络错误，服务器错误，后端抛出的错误)，可以弹出一个错误提示，告诉用户
    if (res.status === -404) {
        alert(res.msg)
        /*router.push({
            path: '/'
        })*/
    }
    if (res.data && !res.data.success) {
        // console(res.data.error_msg)
    }
    return res
}

// 请求方式的配置
export default {
    post(url, param) {
        // console.log("data----->", data);
        url = url + "?" + param;
        return axios({
            method: 'post',
            baseURL: '',
            url,
            data: null,
            timeout: 50000 * 3,
            transformRequest: [
                function (data) {
                    // Do whatever you want to transform the data
                    let ret = '';
                    for (let it in data) {
                        ret +=
                            encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
                    }
                    return ret
                },
            ],
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        }).then((response) => {
            return checkStatus(response)
        }).then((res) => {
            return checkCode(res)
        })
    },

    get(url, params) {
        // get
        // console.log("get-params--->", params);
        return axios({
            method: 'get',
            baseURL: '',
            url,
            params, // get 请求时带的参数
            timeout: 5000,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
        }).then((response) => {
            return checkStatus(response)
        }).then((res) => {
            return checkCode(res)
        })
    }
}